import { faSdg3 } from '@awesome.me/kit-335a9e77a1/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartCard, InfoTooltip, LabelText, VerticalBar } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { getReferenceLines } from '../-helpers/getReferenceLines';
import { getTarget } from '../-helpers/getSDGTargetData';
import { useChartData } from '../-hooks/useChartData';

export const Sdg_PatientsSavingPremiumFragment = gql(/* GraphQL */ `
  fragment Sdg_PatientsSavingPremiumFragment on HealthcareIndicators {
    patientsSavingPremium {
      average
      median
      n
      isDataSuppressed
    }
  }
`);

type PatientsSavingPremiumProps = {
  className?: string;
  indicators: FragmentType<typeof Sdg_PatientsSavingPremiumFragment>;
  viewerIndicators?: FragmentType<typeof Sdg_PatientsSavingPremiumFragment>;
  region: string;
};

export function PatientsSavingPremium({
  indicators,
  viewerIndicators,
  region,
  className,
}: PatientsSavingPremiumProps) {
  const { t } = useTranslation();

  const benchmarkFragment = useFragment(Sdg_PatientsSavingPremiumFragment, indicators);
  const viewerFragment = useFragment(Sdg_PatientsSavingPremiumFragment, viewerIndicators);

  const benchmarkData = benchmarkFragment.patientsSavingPremium;
  const viewerData = viewerFragment?.patientsSavingPremium;

  const sdgTarget = useMemo(() => getTarget('patientsSavingPremium', region), [region]);
  const referenceLines = getReferenceLines(sdgTarget);

  const { groups, data, yAxisProps } = useChartData({
    viewerData,
    benchmarkData,
    referenceLineValues: referenceLines.map((x) => x.value),
  });

  const titleTooltip = t('patientsSavingPremium_titleTooltip_sdg');

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <div className="flex items-center gap-1.5">
          <FontAwesomeIcon icon={faSdg3} size="2x" className="text-sdg-3" />
          <LabelText>{t('sdgsTargeted_optionLabel_3').replace('3', '3.8.2')}</LabelText>
        </div>
        <ChartCard.Title>
          {t('patientsSavingPremium_title')}
          {titleTooltip && <InfoTooltip>{titleTooltip}</InfoTooltip>}
        </ChartCard.Title>
      </ChartCard.Header>
      <ChartCard.Body>
        <VerticalBar groups={groups} data={data}>
          <VerticalBar.Chart yAxis={yAxisProps} referenceLines={referenceLines} />
          <VerticalBar.Legend className="flex items-center justify-center gap-4" />
        </VerticalBar>
      </ChartCard.Body>
    </ChartCard>
  );
}
