import { createFileRoute } from '@tanstack/react-router';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BorderBox, InfoTooltip, SectionTitle, Select, SelectOption } from 'ui-v2';

import { InvalidFilters } from '@/components/InvalidFilters';
import { useIsValidFilters } from '@/hooks/useIsValidFilters';

import { DecentJobs } from './-components/DecentJobs';
import { IncreasingMedicalAccess } from './-components/IncreasingMedicalAccess';
import { InvesteeRevenueGrowth } from './-components/InvesteeRevenueGrowth';
import { PatientsSavingPremium } from './-components/PatientsSavingPremium';
import { PatientsTreated } from './-components/PatientsTreated';
import { getRegionsByType } from './-helpers/getSDGTargetData';
import { useSdgData } from './-hooks/useSdgData';

export const Route = createFileRoute('/sustainable-development-goals')({
  component: Sdg,
});

function Sdg() {
  const { t } = useTranslation();

  const regions = useMemo(
    () =>
      getRegionsByType('region').map((region) => ({
        value: region.toLowerCase(),
        label: region,
        section: 'Region',
      })),
    []
  );

  const countries = useMemo(
    () =>
      getRegionsByType('country').map((country) => ({
        value: country.toLowerCase(),
        label: country,
        section: 'Country',
      })),
    []
  );

  const sdgOptions: SelectOption<string>[] = [
    { value: 'World', label: 'World' },
    ...regions,
    ...countries,
  ];

  const [region, setRegion] = useState<SelectOption<string> | null>(sdgOptions[0]);

  const { loading, isValid } = useIsValidFilters();
  const { benchmarkData, viewerData } = useSdgData(region, { skip: !isValid || loading });

  if (loading) return null;
  if (!isValid) return <InvalidFilters />;
  if (!benchmarkData) return null;

  const chartProps = {
    indicators: benchmarkData,
    viewerIndicators: viewerData ?? undefined,
    region: region?.value as string,
  };

  const titleTooltip = t('sdgPage_titleTooltip');

  return (
    <BorderBox>
      {/* header */}
      <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
        <div className="flex flex-wrap items-baseline gap-x-3 gap-y-2">
          <div className="flex items-center gap-1.5">
            <SectionTitle>{t('sdgPage_title')}</SectionTitle>
            {titleTooltip && <InfoTooltip>{titleTooltip}</InfoTooltip>}
          </div>
          <p className="text-gray-900">{t('sdgPage_subtitle')}</p>
        </div>
        <div className="min-w-[18.75rem]">
          <Select
            options={sdgOptions}
            value={region}
            onChange={setRegion}
            title="SDG Level"
            searchable
            placeholder="Select Region or Country"
          />
        </div>
      </div>
      {/* body */}
      <div className="grid grid-cols-1 gap-6 pt-9 md:grid-cols-2">
        <PatientsTreated {...chartProps} />
        <IncreasingMedicalAccess {...chartProps} />
        <PatientsSavingPremium {...chartProps} />
        <DecentJobs {...chartProps} />
        <InvesteeRevenueGrowth {...chartProps} />
      </div>
    </BorderBox>
  );
}
